import Link from "next/link";

export const Button = ({ linkTo, className, title, onClick, ariaLabel }) => {
  return (
    <Link href={linkTo}>
      <a className={className} onClick={onClick} aria-label={ariaLabel}>
        <span>{title}</span>
      </a>
    </Link>
  );
};
