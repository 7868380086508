import Head from "next/head";
import Image from "next/image";
import Link from "next/link";

// Components
import { Button } from "../components/Button";
import { Ticker } from "../components/Ticker";
import { Awards } from "../components/Awards";
import { ClientsSlideshow } from "../components/ClientsSlideshow";
import { AnimatedLogo } from "../components/AnimatedLogo";

// Data
import { homepageInfoCardsData, portfolioData } from "../data/homePageData";
import { VisuallyHidden } from "@chakra-ui/react";

const imageLoader = require("../loader.js");

export const getStaticProps = async () => {
  return {
    props: {
      homepageInfoCardsData,
      portfolioData,
    },
  };
};

export default function Home({ homepageInfoCardsData, portfolioData }) {
  const addBgColor = (color) => {
    document.querySelector("section.portfolio").style.backgroundColor = color;
  };

  const removeBgColor = () => {
    document.querySelector("section.portfolio").style.backgroundColor = "#fff";
  };

  return (
    <>
      <Head>
        <title>egegen</title>
        <meta name="og:title" content="egegen ecommerce" />
        <meta
          name="description"
          content="egegen is a 360 degree digital agency"
        />
      </Head>

      <section className="hero">
        <div className="section-wrapper">
          <div className="hero-text-container">
            <h1>Building Brands.</h1>
            <h1>Creating Experiences.</h1>
            <h1>Transforming Businesses.</h1>
            <p>
              Digitize your business with Data-Driven Web Design, Custom App
              Development, and marketing strategies ensured to deliver ROI.
            </p>
            <div className="hero-button">
              <Button
                linkTo={"/services"}
                className={"btn glowing-border"}
                title={"Our Services"}
              />
            </div>

            <div className="partners-grid">
              <div className="partner-logo">
                <Image
                  loader={imageLoader}
                  width={120}
                  height={55}
                  objectFit="contain"
                  src={"clutch.svg"}
                  alt="clutch"
                />
              </div>
              <div className="partner-logo">
                <Image
                  loader={imageLoader}
                  width={120}
                  height={55}
                  objectFit="contain"
                  src={"google-partner.svg"}
                  alt="google-partner"
                />
              </div>
              <div className="partner-logo">
                <Image
                  loader={imageLoader}
                  width={120}
                  height={55}
                  objectFit="contain"
                  src={"shopify.svg"}
                  alt="shopify"
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="introduction">
        <div className="section-wrapper flex-column">
          <h1>We Help Growing Businesses Dominate Their Markets</h1>
          <p>
            Trust your growth to a multiple Horizon, Altin Orumcek, and Inc.
            5000 Award-winning digital agency.
          </p>
          <p>
            egegen helps growth-focused businesses achieve ROI-driven strategies
            by providing bespoke technology and digital marketing expertise.
          </p>
          <h2>Start a Conversation</h2>
          <Button
            title="contact us"
            linkTo={"/contact-us"}
            className="btn glowing-border"
          />
        </div>
        <AnimatedLogo />
      </section>

      <section className="acceleration">
        <div className="section-wrapper flex-column">
          <h1>Accelerating Your Business’s Growth</h1>
          <p>
            Transforming business challenges into unforgettable web and mobile
            solutions.
          </p>
          {homepageInfoCardsData && (
            <div className="info-card-container">
              {homepageInfoCardsData.map(
                (
                  { icon, infoCardTitle, sublinks, techIcons, subPageLink },
                  index
                ) => (
                  <div key={index} className="info-card">
                    <div className="info-card-icon">
                      <Image
                        loader={imageLoader}
                        layout="fill"
                        objectFit="contain"
                        src={icon}
                        alt={infoCardTitle}
                      />
                    </div>
                    <div className="info-card-title">
                      <h2>{infoCardTitle}</h2>
                    </div>
                    <div className="infoCardSublinkContainer">
                      {sublinks &&
                        sublinks.map((sublink, index) => (
                          <div key={index} className="info-card-sublink">
                            <Link href={sublink.href}>
                              <a className={"infoCardSublinkText"}>
                                {sublink.title}
                              </a>
                            </Link>
                          </div>
                        ))}
                    </div>
                    {techIcons && (
                      <div className={"infoCardTechContainer"}>
                        {techIcons.map((techIcon, index) => (
                          <div className="techIcon" key={index}>
                            <VisuallyHidden>{techIcon.title}</VisuallyHidden>
                            <img
                              src={`/images/${techIcon.src}`}
                              alt={`${techIcon.alt} logo`}
                            />
                          </div>
                        ))}
                      </div>
                    )}
                    <div className="info-card-footer">
                      <Button
                        linkTo={`/services/${subPageLink}`}
                        title={infoCardTitle}
                        className={"btn btn-with-arrow"}
                        ariaLabel={`Learn more about ${infoCardTitle}`}
                      />
                    </div>
                  </div>
                )
              )}
            </div>
          )}
        </div>
      </section>

      {portfolioData && (
        <section className="portfolio">
          <div className="section-wrapper flex-column">
            <h3>OUR PORTFOLIO</h3>
            <h1>
              Take a Look at Some of the 1500+ Projects Our Design & Marketing
              Experts Have Done!
            </h1>
            {portfolioData.map(
              (
                {
                  className,
                  bgColor,
                  projectTags,
                  title,
                  text,
                  linkUrl,
                  imageUrl,
                },
                index
              ) => (
                <div
                  key={index}
                  className={`homepage-project ${className}`}
                  onMouseOver={() => addBgColor(bgColor)}
                  onMouseLeave={removeBgColor}
                >
                  <div className="project-text-container">
                    {projectTags && (
                      <div className="project-tag-container">
                        {projectTags.map(({ tagName }, index) => (
                          <p key={index} className="project-tag">
                            {tagName}
                          </p>
                        ))}
                      </div>
                    )}
                    <div className="project-title">
                      <h1>{title}</h1>
                    </div>
                    <div className="project-text">
                      <p>{text}</p>
                    </div>
                    <div className="project-link">
                      <Button
                        linkTo={linkUrl}
                        title={"See Case Study"}
                        className={"btn-with-arrow"}
                      />
                    </div>
                  </div>
                  <div className="project-image-container">
                    <Image
                      loader={imageLoader}
                      width={700}
                      height={500}
                      objectFit="contain"
                      src={imageUrl}
                      alt={title}
                    />
                  </div>
                </div>
              )
            )}
          </div>
        </section>
      )}

      <section className="values">
        <div className="section-wrapper">
          <div className="flex justify-between">
            <div className="values-text-container">
              <h3>WHY EGEGEN</h3>
              <h1>Driven By Shared Values.</h1>
              <h2>Ownership</h2>
              <p>
                Making Your Business Ours! From Start To Finish, Your Success Is
                Our Success.
              </p>
              <h2>Innovation</h2>
              <p>
                Stay Ahead Of The Game! We Believe In The Kind Of Change That
                Catapults Optimal Performance.
              </p>
              <h2>Transparency</h2>
              <p>
                Let’s Be Honest – Open Communication Is The Key To Successful
                Partnerships And You Can Trust We’ll Be Open With You Every Step
                Of The Way!
              </p>
            </div>
            <div className="values-image-container">
              <Image
                loader={imageLoader}
                width={520}
                height={520}
                src={"driven_vector.svg"}
                alt="driven"
              />
            </div>
          </div>
        </div>
        <div className="values-numbers-container">
          <div className="values-numbers">
            <Ticker end={500} suffix="+" />
            <h1>CUSTOMERS</h1>
          </div>
          <div className="values-numbers">
            <Ticker end={70} suffix="+" separator="," />
            <h1>TEAM MEMBERS</h1>
          </div>
          <div className="values-numbers">
            <Ticker end={1500} suffix="+" separator="," />
            <h1>PROJECTS</h1>
          </div>
        </div>
      </section>

      <ClientsSlideshow
        slideShowTitle={
          " Our Experts Have Completed 1500 Digital Projects for 600+ Brands."
        }
      />

      <Awards />
    </>
  );
}
