import Image from "next/image";
import { motion } from "framer-motion";
const imageLoader = require("../loader.js");

const variants = {
  visible: {
    y: ["0%", "-80%", "0%"],
    rotate: 360,
  },
};

export const AnimatedLogo = () => {
  return (
    <motion.div
      className="egegen-logo-animated"
      animate={"visible"}
      transition={{ duration: 7 }}
      variants={variants}
    >
      <Image
        loader={imageLoader}
        src={"oscar-egegen-gray.svg"}
        alt="egegen-logo"
        width={220}
        height={220}
      />
    </motion.div>
  );
};
