import Image from "next/image";

import { Button } from "../components/Button";

const imageLoader = require("../loader.js");

export const Awards = () => {
  return (
    <section className={"ourAwards"}>
      <div className="section-wrapper align-center justify-center flex-column">
        <div className={"egegenAwards"}>
          <div className={"egegenAwardsCard"}>
            <div className={"egegenAwardsCardImage"}>
              <Image
                loader={imageLoader}
                width={120}
                height={120}
                src={"egegen-horizon-awards-2021-agency.svg"}
                alt="Horizon Distinguished Agency Award"
              />
            </div>
            <p>Horizon Distinguished Agency Award</p>
          </div>
          <div className={"egegenAwardsCard"}>
            <div className={"egegenAwardsCardImage"}>
              <Image
                loader={imageLoader}
                width={120}
                height={120}
                src={"egegen-horizon-awards-gold.svg"}
                alt="Horizon Gold Award"
              />
            </div>

            <p>Horizon Gold Award</p>
          </div>
          <div className={"egegenAwardsCard"}>
            <div className={"egegenAwardsCardImage"}>
              <Image
                loader={imageLoader}
                width={120}
                height={120}
                src={"egegen-altin-orumcek-kristal-odul.png"}
                alt="Altin Orumcek Award"
              />
            </div>
            <p>Altin Orumcek Award</p>
          </div>
          <div className={"egegenAwardsCard"}>
            <div className={"egegenAwardsCardImage"}>
              <Image
                loader={imageLoader}
                width={120}
                height={120}
                src={"egegen-horizon-awards-silver.svg"}
                alt="Horizon Silver Award"
              />
            </div>

            <p>Horizon Silver Award</p>
          </div>
          <div className={"egegenAwardsCard"}>
            <div className={"egegenAwardsCardImage"}>
              <Image
                loader={imageLoader}
                width={120}
                height={120}
                src={"egegen-horizon-awards-bronze.svg"}
                alt="Horizon Bronze Award"
              />
            </div>
            <p>Horizon Bronze Award</p>
          </div>
        </div>
        <Button
          linkTo={"/our-awards"}
          className={"btn glowing-border"}
          title={"See all awards"}
        />
      </div>
    </section>
  );
};
