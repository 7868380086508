import Image from "next/image";

// Libraries
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper";
import "swiper/css";
import "swiper/css/autoplay";

import { clientsSlideShowData } from "../data/clientsSlideShowData";

const imageLoader = require("../loader.js");

export const ClientsSlideshow = ({ slideShowTitle }) => {
  return (
    <section className={"clientsSlide"}>
      <div className="section-wrapper flex-column">
        <div className={"clientsSlideText"}>
          <h2>{slideShowTitle}</h2>
        </div>
        <div className={"clientsSlideSlideshow"}>
          <Swiper
            modules={[Autoplay]}
            autoplay={{
              delay: 2500,
              disableOnInteraction: false,
            }}
            breakpoints={{
              375: {
                slidesPerView: 1,
                spaceBetween: 15,
              },
              475: {
                slidesPerView: 2,
                spaceBetween: 20,
              },
              600: {
                slidesPerView: 3,
                spaceBetween: 30,
              },
              768: {
                slidesPerView: 4,
                spaceBetween: 40,
              },
              1024: {
                slidesPerView: 5,
                spaceBetween: 50,
              },
            }}
          >
            {clientsSlideShowData.map((client, index) => (
              <SwiperSlide key={index}>
                <Image
                  loader={imageLoader}
                  width={120}
                  height={120}
                  src={client.src}
                  alt={client.alt}
                  className={"clientLogoSmall"}
                />
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
    </section>
  );
};
