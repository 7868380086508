export const clientsSlideShowData = [
  {
    src: 'alfemo-logo.png',
    alt: 'alfemo',
  },
  {
    src: 'aynes-logo.svg',
    alt: 'aynes',
  },
  {
    src: 'aynes-kolajen-logo.png',
    alt: 'aynesKolajen',
  },
  {
    src: 'bambi-logo.png',
    alt: 'bambi',
  },
  {
    src: 'betonstar-logo.png',
    alt: 'betonstar',
  },
  {
    src: 'betonstar-logo.png',
    alt: 'bmcTugra',
  },
  {
    src: 'betonstar-logo.png',
    alt: 'bonvivant',
  },
  {
    src: 'betonstar-logo.png',
    alt: 'ecaSerel',
  },
  {
    src: 'betonstar-logo.png',
    alt: 'edina',
  },
  {
    src: 'betonstar-logo.png',
    alt: 'egegen',
  },
  {
    src: 'betonstar-logo.png',
    alt: 'fide',
  },
  {
    src: 'betonstar-logo.png',
    alt: 'genova',
  },
  {
    src: 'betonstar-logo.png',
    alt: 'haremLondon',
  },
  {
    src: 'betonstar-logo.png',
    alt: 'iva',
  },
  {
    src: 'betonstar-logo.png',
    alt: 'kidsTeens',
  },
  {
    src: 'betonstar-logo.png',
    alt: 'kocaer',
  },
  {
    src: 'betonstar-logo.png',
    alt: 'lezita',
  },
  {
    src: 'betonstar-logo.png',
    alt: 'makaroma',
  },
  {
    src: 'betonstar-logo.png',
    alt: 'otistiklerDernegi',
  },
  {
    src: 'betonstar-logo.png',
    alt: 'ozsut',
  },
  {
    src: 'betonstar-logo.png',
    alt: 'rame',
  },
  {
    src: 'betonstar-logo.png',
    alt: 'reflex',
  },
  {
    src: 'betonstar-logo.png',
    alt: 'sanita',
  },
  {
    src: 'betonstar-logo.png',
    alt: 'spaceCamp',
  },
  {
    src: 'betonstar-logo.png',
    alt: 'spectrum',
  },
  {
    src: 'betonstar-logo.png',
    alt: 'ugm',
  },
];
